import React from 'react';

function Home() {
    return (
        <div className="container ">
        <div className="mx-1 pt-2">
            <h1 className="font-weight-light">Atlas Mundial</h1>
            <hr/>
            <p> 
                Atlas mundial, es un proyecto que intentará albergar datos geopolitícos de todo el globo. 
                En esta primer versión muestra datos geopolitícos de los paises agrupados por contientes y 
                algunas agrupaciones por el idioma principal.
            </p>
            <p>
                Además puedes utilizar directamente el buscador en la barra de navegación. La 
                busqueda  se realiza con el nombre en inglés, aunque puedes utilizar su nombre oficial y 
                además utilizar nombres parciales. Prueba con United, por ejemplo.
            </p>
            <span>Versions</span>
            <ul>
                <li>Añadido el menu de idiomas</li>
                <li>Incluido detalle-pais las fronteras navegables</li>
                <li>Traducida la interfaz al inglés en su totalidad</li>
                <li>Añadido iconos propios</li>
                <li>Añadido el <b>quiz de banderas</b></li>
                <li>Busqueda por idioma y moneda, desde el detalle-Pais</li>
                <li>Añadido high score del jugador</li>
                <li>Añadido el <b>un trivial de preguntas de </b>geografía</li>
            </ul>
            <p>
               Desarrollado en su totalidad con React y Bootstrap 4. Los datos son recuperados a través de una API REST 
               proporcionada por REST COUNTRIES.
            </p>
        </div>
        </div>

    )
}

export default Home;
