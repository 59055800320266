import React, { Component } from 'react';

class CountryDetail extends Component {

    constructor(props) {
        super(props);

        this.handleOnClickCode = this.handleOnClickCode.bind(this);
        this.handleOnClickLanguage = this.handleOnClickLanguage.bind(this);
        this.handleOnClickCurrency = this.handleOnClickCurrency.bind(this);
    }

    handleOnClickLanguage(e) {
        e.preventDefault();
        const language = e.target.id;
        this.props.onClickLanguage(language);

    }

    handleOnClickCode(e) {
        e.preventDefault();
        const code = e.target.id;
        this.props.onClickCode(code);
    }

    handleOnClickCurrency(e) {
        e.preventDefault();
        const currency = e.target.id;
        this.props.onClickCurrency(currency);
    }


    render() {

        const { name, capital, flag, alpha3Code, population, area, borders, nativeName, currencies, region, subregion, languages }
            = this.props.country;

        return (
            <div className="col-12 col-sm-8 col-md-6 col-lg-6 mx-auto mb-4">
                <div className="card">
                    <img src={flag} alt={name} className="card-img-top rounded" />
                    <div className="card-body">
                        <h1 className="card-title">{name}&nbsp;</h1>
                        <p>
                            <span className="card-text">Capital: {capital}</span><br />
                            <span className="card-text">Popultaion: {new Intl.NumberFormat().format(population)} hab.</span><br />
                            <span className="card-text">Area: {new Intl.NumberFormat().format(area)} m&sup2;</span><br />
                            <span className="card-text">Native name: {nativeName} ({alpha3Code})</span><br />
                            <span className="card-text">
                                Currencies:&nbsp;
                            {currencies.map(currency => (
                                <a key={currency.code} className="inline" href={currency.code} id={currency.code}
                                    onClick={this.handleOnClickCurrency}>{currency.name},&nbsp;</a>
                            ))}
                            </span><br/>
                            <span className="card-text">
                                Languages:&nbsp;
                            {languages.map(language => (
                                <a key={language.name} className="inline" href={language.name} id={language.iso639_1}
                                    onClick={this.handleOnClickLanguage}>{language.name},&nbsp;</a>
                            ))}
                            </span>
                        </p>
                    </div>
                    <div className="card-footer">
                        <p className="card-text">
                            <span className="card-text">Region: {region}</span><br />
                            <span className="card-text">Subregion: {subregion}</span><br />
                            Borders:&nbsp;
                            {borders.map(border => (
                                <a key={border} className="inline" href={border} id={border}
                                    onClick={this.handleOnClickCode}>{border},&nbsp;</a>
                            ))}
                        </p>
                    </div>
                </div >
            </div >
        )
    }

}

export default CountryDetail;