
export const myRandom = (maximo) => {
  return Math.round(Math.random()* maximo);
}

export const decodeHTML = (html) => {
	const txt = document.createElement('textarea');
	txt.innerHTML = html;
	return txt.value;
};

