import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
    render() {

        return (
            <div className="modal fade" id={this.props.id}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="title">{this.props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" hidden={this.props.notClosed}>&times;</button>
                        </div>
                        <div className="modal-body">
                            {this.props.children}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal" hidden={this.props.notClosed}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    title : PropTypes.string,
    id: PropTypes.string.isRequired,
    notClosed : PropTypes.bool,
    children: PropTypes.node
};

export default Modal;