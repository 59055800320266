import React, { Component } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer'

import './App.css';


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }

  }
  componentDidMount() {
    console.log("monta app");

  }

  componentWillUnmount() {
    console.log("desmonta app");

  }

  /*
    Función para crear enlaces director realizar un manejador de querys
  pathName() {
    return window.location.pathname.substring(1);
  }*/

  render() {
    return (
      <div className="App">
        <Navbar />
        <Footer />
      </div>
    );
  }
}

export default App;
