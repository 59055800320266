export default {

    API_COUNTRIES : 'https://restcountries.eu/rest/v2/name/',
    API_REGIONS : 'https://restcountries.eu/rest/v2/region/',
    API_CODE : 'https://restcountries.eu/rest/v2/alpha/',
    API_CURRENCY : 'https://restcountries.eu/rest/v2/currency/',
    API_LANGUAGE : 'https://restcountries.eu/rest/v2/lang/',

    NUMBER_QUESTIONS : 10,
    API_TRIVIA : 'https://opentdb.com/api.php?amount=10&category=22',

    REGIONS : ['Africa', "Americas", "Asia", "Europe", "Oceania"],
    LANGUAGE : [
                { name : 'English', code : 'en'},
                { name : 'Spanish', code : 'es'},
                { name : 'French', code : 'fr'},
                { name : 'Arab', code : 'ar'},
                { name : 'Portuguese', code : 'pt'},
                { name : 'Russian', code : 'ru'},
                { name : 'German', code : 'de'},
                { name : 'Italian', code : 'it'},
                { name : 'Chinesse', code : 'zh'},

    ],


};