import React from 'react';
import PropTypes from 'prop-types';


import CountriesThumbnails from './CountriesThumbnails'
import CountryDetail from './CountryDetail';
import Home from './Home'

class CountriesHandler extends React.Component {

    constructor(props) {
        super(props);

        this.handleOnSearchCode = this.handleOnSearchCode.bind(this); 
        this.handleOnSearchLanguage = this.handleOnSearchLanguage.bind(this);
        this.handleOnSearchCurrency = this.handleOnSearchCurrency.bind(this);

        this.state = {
        }

    }

    componentDidMount() {
        //console.log("monta CountriesHandler");
        //console.log(this.props);

    }

    componentWillUnmount() {
        //console.log("desmonta CountriesHandler");

    }

    componentDidUpdate() {

        //console.log("DidUpdate CountriesHandler");

    }


    handleOnSearchCode(code) {

        this.props.onSearchCode(code);
    }

    handleOnSearchLanguage(language) {

        this.props.onSearchLanguage(language);
    }

    handleOnSearchCurrency(currency) {

        this.props.onSearchCurrency(currency);

    }

    dataRender() {

        const { countryData, isLoaded, error } = this.props;

        if (countryData.length === 0) {
            // Es la primera vez que carga la página
            return (
                <Home />
            );
        } else if (countryData.length === 1) {
            // tiene que mostar la ficha detalle viene del boton de la card se puede poner un OR para quitar un if
            return (
                <CountryDetail country={countryData[0]} onClickCode={this.handleOnSearchCode}
                    onClickLanguage={this.handleOnSearchLanguage} onClickCurrency={this.handleOnSearchCurrency} />
            );
        } else if (countryData.name) {
            // ficha detalle busqueda por code
            return (
                <CountryDetail country={countryData} onClickCode={this.handleOnSearchCode}
                    onClickLanguage={this.handleOnSearchLanguage} onClickCurrency={this.handleOnSearchCurrency} />
            );
        } else if (countryData.length === undefined) {
            // undefinided error 404, mejorar este metodo para errores HTTP
            return (
                <div className="container alert-danger m-3 py-3 rounded" role="alert">
                    No se encontraron resultados
                </div>
            );
        } else if (error) {
            // si no hay conexión 
            return (
                <div className="container alert-danger m-3 py-3 rounded" role="alert">
                    Se ha producido un error: {error.message}
                </div>
            );
        } else if (!isLoaded) {
            // tarda el servidor en responder, ¿se podrian utilizar promesas de JQuery?
            return (
                <div className="container alert-danger m-3 py-3 rounded" role="alert">
                    Loading ...
                </div>
            );
        } else {
            return (
                countryData.map(country => (
                    <CountriesThumbnails key={country.alpha2Code} country={country} onClickCode={this.handleOnSearchCode} />
                ))
            );
        }
    }

    render() {
        return (
            <div className="container">
                <div className="py-5 row align-center">
                    {this.dataRender()}
                </div>
            </div>
        );
    }
}

CountriesHandler.propTypes = {
    dataCountry: PropTypes.array, 
    isLoaded: PropTypes.bool.isRequired,
    error : PropTypes.bool,
};

export default CountriesHandler;