import React from 'react';

function Footer() {
    return (
        <div className="container-fluid bg-secondary">
            <p className="bg-secondary text-white text-right">by Juan Luis Lara</p>
        </div>
    );
}

export default Footer;
