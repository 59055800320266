import React, { Component } from 'react';

class CountriesThumbnails extends Component {

    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);


    }

    handleOnClick(e) {
        e.preventDefault();

        const code = e.target.id;
        this.props.onClickCode(code);
    }

    render() {
        const { name, capital, flag, alpha3Code } = this.props.country;

        return (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                <div className="card h-100">
                    <img src={flag} alt={name} className="card-img-top rounded" />
                    <div className="card-body">
                        <h5 className="card-title">{name}</h5>
                        <p className="card-text">Capital: {capital}</p>        
                    </div>
                    <div className="card-footer text-center">
                        <a href="/" id={alpha3Code} className="btn btn-primary btn-sm" onClick={this.handleOnClick}>More data</a>
                    </div>
                </div>
            </div>
        );
    }

}

export default CountriesThumbnails;