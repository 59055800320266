import React, { Component } from 'react';
//import logo from '../images/home.svg'
import C from '../helpers/Constants'

import Home from './Home'
import CountriesHandler from './CountriesHandler'
import Quiz from './Quiz'
import Trivia from './Trivia'



import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

class Navbar extends Component {

    constructor(props) {
        super(props);

        this.countriesRef = React.createRef();

        this.handleOnClickRegion = this.handleOnClickRegion.bind(this);
        this.handleOnClickLanguages = this.handleOnClickLanguages.bind(this);
        this.handleOnClickSearch = this.handleOnClickSearch.bind(this);
        //this.handleInputSearch = this.handleInputSearch.bind(this);

        this.handleSearchCode = this.handleSearchCode.bind(this);
        this.handleSearchLanguage = this.handleSearchLanguage.bind(this);
        this.handleSearchCurrency = this.handleSearchCurrency.bind(this);

        this.clickToggleNavbar = this.clickToggleNavbar.bind(this);
        this.handleToggleNavbar = this.handleToggleNavbar.bind(this);

        //revisar bien que se utiliza
        this.state = {
            error: null,
            isLoaded: false,
            countryData: [],
            collapsed: true,
        };

    }

    clickToggleNavbar() {
        if (!this.state.collapsed) {
            var toogle = document.getElementById('btnToogler');
            toogle.click();
        }
    }

    handleToggleNavbar(e) {
        console.log("Click toogle");
        console.log(this.state.collapsed);
        this.setState({ collapsed: !this.state.collapsed });
    }

    /*clickToggleNavbar() {
        console.log ("prueba");
        var toogle = document.getElementById('btnToogler');
        console.log (toogle.toggleAttribute);
        if (this.state.collapse) toogle.click();
    }*/

    handleOnClickRegion(e) {
        const region = e.target.id;
        this.setState({ collapsed: true });
        this.setState({
        },
            () => {
                this.GetApiRest(C.API_REGIONS, region);
            })
    }

    handleOnClickLanguages(e) {

        const language = e.target.id;
        this.setState({ collapsed: true });
        this.setState({
        },
            () => {
                this.GetApiRest(C.API_LANGUAGE, language);
            })
    }

    handleSearchLanguage(language) {
        this.setState({
        },
            () => {
                this.GetApiRest(C.API_LANGUAGE, language);
            })
    }

    handleSearchCurrency(currency) {
        this.setState({
        },
            () => {
                this.GetApiRest(C.API_CURRENCY, currency);
            })
    }

    handleSearchCode(code) {
        this.setState({
        },
            () => {
                this.GetApiRest(C.API_CODE, code);
            })

    }

    handleOnClickSearch(e) {
        //e.preventDefault();
        const country = this.countriesRef.current.value;
        this.setState({ collapsed: true });
        this.setState({
            country: country
        },
            () => {
                this.GetApiRest(C.API_COUNTRIES, country);
            });
    }

    handleInputSearch(e) {
        e.preventDefault();
        console.log(e);
        console.log('input');
        //this.setState ({collapsed : true});
    }

    GetApiRest = (url, query) => {
        const myUrl = url + query;

        console.log(myUrl)
        fetch(myUrl)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        countryData: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {

        const { error, isLoaded, countryData } = this.state;

        const collapsed = this.state.collapsed;
        const classDiv = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classBtn = collapsed ? 'navbar-toggler collapsed' : 'navbar-toggler';

        return (

            <Router>
                <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                    <a className="navbar-brand" href="/">Atlas Mundial</a>
                    <button id="btnToogler" className={classBtn} type="button" onClick={this.handleToggleNavbar}
                        data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={classDiv} id="navbarToggler">
                        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" key="home" id="home" to="/" onClick={this.clickToggleNavbar}>Home</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
                                    Regions
                            </a>
                                <div className="dropdown-menu">
                                    {C.REGIONS.map(region => (
                                        <Link className="dropdown-item" key={region} id={region} to="/countries"
                                            onClick={this.handleOnClickRegion}>{region}</Link>
                                    ))}
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
                                    Languages
                            </a>
                                <div className="dropdown-menu">
                                    {C.LANGUAGE.map(language => (
                                        <Link className="dropdown-item" key={language.code} id={language.code} to="/countries"
                                            onClick={this.handleOnClickLanguages}>{language.name}</Link>
                                    ))}
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" key="quiz" id="quiz" to="/quiz" onClick={this.clickToggleNavbar}>Quiz</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" key="quiz" id="trivia" to="/trivia" onClick={this.clickToggleNavbar}>Trivia</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link disabled" href="/">About</a>
                            </li>
                        </ul>
                        <form className="form-inline my-2 my-lg-0">
                            <input ref={this.countriesRef} className="form-control mr-sm-2" type="search" onSubmit={this.handleInputSearch} placeholder="Country name ..." aria-label="Country name" />
                            <Link className="btn btn-outline-success my-2 my-sm-0" to="/countries" onClick={this.handleOnClickSearch}>Search</Link>
                        </form>
                    </div>
                </nav>
                <Switch>
                    <Route path="/quiz">
                        <Quiz />
                    </Route>
                    <Route path="/countries">
                        <CountriesHandler countryData={countryData} isLoaded={isLoaded} error={error}
                            onSearchCode={this.handleSearchCode} onSearchLanguage={this.handleSearchLanguage} onSearchCurrency={this.handleSearchCurrency}
                        />
                    </Route>
                    <Route path="/trivia">
                        <Trivia />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </Router>
        )
    }
}

export default Navbar;
