import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import Modal from './Modal'

import C from '../helpers/Constants'
import * as Util from '../helpers/Util'

import '../App.css';

class Trivia extends Component {
    constructor(props) {
        super(props);


        this.handleOnclick = this.handleOnclick.bind(this);
        this.handleOnClickInit = this.handleOnClickInit.bind(this);
        this.handleOnClickExit = this.handleOnClickExit.bind(this);
        const cookies = new Cookies();
        const record = cookies.get('recordTrivia');

        this.state = {
            questions: [],
            isLoaded: false,
            possibles: null,

            currentQuestion: 0,

            answer: '',
            showAnswer: false,
            score: 0,

            record: record,
            newRecord: false,
        };


    }

    choosePossibleAnswers(question) {
        //const title = '&#039;archipelago&#039;.';
        //console.log (Util.decodeHTML(title));

        const type = question.type;
        let possibles = question.incorrect_answers;
        if (type === 'boolean') {
            possibles.splice(Util.myRandom(2), 0, question.correct_answer); //mirar si siempre son true false
        } else {
            possibles.splice(Util.myRandom(4), 0, question.correct_answer); //inserta en una posición aleatoria
        }
        return possibles;

    }

    componentDidMount() {
        console.log("monta trivia");
        this.GetApiRest();
        //cargar this.choosePossibleAnswers
        //this.firstQuestion(questions.results[0]);

    }

    componentWillUnmount() {
        //console.log("desmonta quiz");
    }

    componentDidUpdate() {

        //console.log("Se actualiza quiz");

    }

    isRight(eleccion) {
        return eleccion === this.state.questions[this.state.currentQuestion].correct_answer;
    }

    isGameOver() {
        console.log (this.state.currentQuestion);
        return this.state.currentQuestion+1 >= C.NUMBER_QUESTIONS;
    }

    playSound(eleccion) {
        let sound;
        this.isRight(eleccion) ? sound = document.getElementById('audioRight') : sound = document.getElementById('audioWrong');
        sound.play();
    }

    handleOnClickInit(e) {
        e.preventDefault();
        this.initGame();
    }

    handleOnClickExit(e) {
        e.preventDefault();
        window.location.href = "/";
    }

    handleOnclick(e) {
        e.preventDefault();
        if (this.state.showAnswer) return; //prevent multiclick

        const eleccion = e.target.id;
        let score = this.state.score;

        this.playSound(eleccion);

        if (this.isRight(eleccion)) {
            score++;
        }

        //console.log(lifes);
        this.setState({
            answer: eleccion,
            score: score,
            showAnswer: true,
        })

        if (!this.isGameOver()) {
            this.timerNextQuestion();
        }
        else {
            this.gameOver(score); //hay que pasarle el score porque si acierta la ultima pregunta no se ha actualizado el estado
        }
    }

    timerNextQuestion() {
        const timer = setTimeout(() => {
            this.nextQuestion();
        }, 2500);
        return () => clearTimeout(timer);
    }

    gameOver(score) {
        const {record} = this.state;

        if ((record == undefined && score > 0) || (record < score)) {
            const cookies = new Cookies();
            cookies.set('recordTrivia', score, { expires: new Date(2040, 12) });
            this.setState({
                record: score,
                newRecord: true,
            });
        }
        var link = document.getElementById('gameOver');
        link.click();
    }

    firstQuestion(question) {

        //console.log (this.state.question);
        this.setState({
            possibles: this.choosePossibleAnswers(question),

        });

    }

    nextQuestion() {

        const nextQuestion = this.state.currentQuestion + 1;

        this.setState({
            possibles: this.choosePossibleAnswers(this.state.questions[nextQuestion]),
            answer: '',
            showAnswer: false,
            currentQuestion: nextQuestion,

        });

    }


    initGame() {
        
        this.GetApiRest ();

        this.setState({

            currentQuestion: 0,

            answer: '',
            showAnswer: false,
            score: 0,

            newRecord: false,
        });

    }


    classButton(answer) {

        if (this.state.showAnswer) {
            if (this.isRight(answer)) {
                return "btn btn-lg btn-success btn-block";
            } else {
                return this.state.answer === answer ? "btn btn-lg btn-danger btn-block" : "btn btn-lg btn-warning btn-block"
            }
        }

        return "btn btn-lg btn-warning btn-block";

    }

    GetApiRest = () => {
        const myUrl = C.API_TRIVIA;

        console.log(myUrl)
        fetch(myUrl)
            .then(response => response.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        questions: result.results,
                        possibles: this.choosePossibleAnswers (result.results[0]),
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {

        const { questions, isLoaded, possibles, currentQuestion } = this.state;

        if (isLoaded) {

            const question = questions[currentQuestion];

            return (
                <div id="Trivia" className="container p-2">
                    <div className="contanier col-12 col-sm-10 col-md-6 col-lg-6 mx-auto pt-1 pb-5">
                        <div className="card">
                            <div className="card-header">
                                <h5>Question: {this.state.currentQuestion + 1}/{C.NUMBER_QUESTIONS}</h5>
                            </div>
                            <div className="card-body">
                                <h5>{Util.decodeHTML(question.question)}</h5>
                                <hr/>
                                {possibles.map(answer => (
                                    <div className="container pt-2" key={answer}>
                                        <a key={answer} id={answer} className={this.classButton(answer)} href="/" onClick={this.handleOnclick}>{Util.decodeHTML(answer)}</a>
                                    </div>
                                ))}
                            </div>
                            <div className="card-footer text-center">
                                <div className="row">
                                    <div className="col-8">
                                        <h5>Score : {this.state.score}</h5>
                                    </div>
                                    <div className="col-4">
                                        <button id="pausa" className="btn btn-secondary btn-sm" data-toggle="modal" data-target="#modalPausa">Pause</button>
                                        <button id="gameOver" className="btn btn-secondary btn-sm" data-toggle="modal" data-target="#modalGameOver" hidden>Fin</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal id="modalPausa" title="Flags Quiz">
                        <button type="button" className="btn btn-success btn-block" data-dismiss="modal" onClick={this.handleOnClickInit}>Restart</button>
                        <button type="button" className="btn btn-danger btn-block" data-dismiss="modal" onClick={this.handleOnClickExit}>Exit Game</button>
                    </Modal>
                    <Modal id="modalGameOver" notClosed={true} title={this.state.newRecord ? 'Good Job !!!' : 'Game Over'}>
                        {(this.state.newRecord ?
                            <h2 className="text-success">New high score {this.state.score}</h2> :
                            <h2>Score : {this.state.score}<small className="float-right">Your high score: {this.state.record}</small></h2>)}
                        <button type="button" className="btn btn-success btn-block" data-dismiss="modal" onClick={this.handleOnClickInit}>Play Again</button>
                        <button type="button" className="btn btn-danger btn-block" data-dismiss="modal" onClick={this.handleOnClickExit}>Exit Game</button>
                    </Modal>
                    <div id="divAudio">
                        <audio id="audioRight" controls hidden>
                            <source className="hidden" src="sounds/BellTransition.mp3" type="audio/mpeg" />
                        </audio>
                        <audio id="audioWrong" controls hidden>
                            <source className="hidden" src="sounds/HighHonk.m4a" type="audio/mp4" />
                        </audio>
                    </div>
                </div>
            );
        } else { //Cargado API Rest
            return (
                <div id="Trivia" className="container p-2">
                    <div className="contanier col-12 col-sm-10 col-md-6 col-lg-6 mx-auto pt-1 pb-5">
                        <div className="card">
                            <div className="card-header">
                                <h5>Question: {this.state.currentQuestion}/{C.NUMBER_QUESTIONS}</h5>
                            </div>
                            <div className="card-body">
                                <h5 className="text-primary tex">Loading questions ...
                                    <span className="spinner-grow text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </span>
                                </h5>
                            </div>
                            <div className="card-footer text-center">
                                <div className="row">
                                    <div className="col-8">
                                        <h5>Score : {this.state.score}</h5>
                                    </div>
                                    <div className="col-4">
                                        <button id="pausa" className="btn btn-secondary btn-sm" data-toggle="modal" data-target="#modalPausa">Pause</button>
                                        <button id="gameOver" className="btn btn-secondary btn-sm" data-toggle="modal" data-target="#modalGameOver" hidden>Fin</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        }
    }
}

export default Trivia;