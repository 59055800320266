import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import Modal from './Modal'

import * as Util from '../helpers/Util'
import Datos from '../data/simplyCountries.json'

import '../App.css';

class Quiz extends Component {
    constructor(props) {
        super(props);
        const right = Datos[Util.myRandom(Datos.length)];
        const possibles = this.choosePossibleAnswers(right.name);

        this.handleOnclick = this.handleOnclick.bind(this);
        this.handleOnClickInit = this.handleOnClickInit.bind(this);
        this.handleOnClickExit = this.handleOnClickExit.bind(this);
        const cookies = new Cookies();
        const record = cookies.get('record');
        console.log('Record:' + record);

        this.state = {
            right: right,
            possibles: possibles,
            answer: '',
            showAnswer: false,
            score: 0,
            lifes: 3,
            record: record,
            newRecord : false,
        };


    }

    choosePossibleAnswers(right) {
        let possibles = [];

        while (possibles.length < 3) {
            const candidate = Datos[Util.myRandom(Datos.length)].name;
            if ((candidate !== right) && (possibles.indexOf(candidate) === -1)) {
                possibles.push(candidate)
            }
        }

        possibles.splice(Util.myRandom(4), 0, right); //inserta en una posición aleatoria
        return possibles;

    }

    componentDidMount() {
        //console.log("monta quiz");

    }

    componentWillUnmount() {
        //console.log("desmonta quiz");
    }

    componentDidUpdate() {

        //console.log("Se actualiza quiz");

    }

    isRight(eleccion) {
        return eleccion === this.state.right.name;
    }

    isGameOver(lifes) {
        return lifes <= 0;
    }

    playSound(eleccion) {
        let sound;
        this.isRight(eleccion) ? sound = document.getElementById('audioRight') : sound = document.getElementById('audioWrong');
        sound.play();
    }

    handleOnClickInit(e) {
        e.preventDefault();
        //console.log("Click Init");
        this.initGame();
    }

    handleOnClickExit(e) {
        e.preventDefault();
        //console.log("Click Exit");
        window.location.href = "/";
    }

    handleOnclick(e) {
        e.preventDefault();
        if (this.state.showAnswer) return; //prevent multiclick

        const eleccion = e.target.id;
        let score = this.state.score;
        let lifes = this.state.lifes;

        this.playSound(eleccion);

        if (this.isRight(eleccion)) {
            score++;
        } else {
            lifes--;
        }

        //console.log(lifes);
        this.setState({
            answer: eleccion,
            score: score,
            lifes: lifes,
            showAnswer: true,
        })

        if (!this.isGameOver(lifes)) {
            this.timerNextQuestion();
        }
        else {
            this.gameOver ();
        }
    }

    timerNextQuestion() {
        const timer = setTimeout(() => {
            this.nextQuestion();
        }, 2500);
        return () => clearTimeout(timer);
    }

    gameOver () {
        const {score, record} = this.state;

        if ((record == undefined && score > 0) || (record < score)) {
            const cookies = new Cookies();
            cookies.set('record', score, {expires: new Date(2040,12)});
            this.setState ({record : score,
                            newRecord : true,
            }); 
        }
        var link = document.getElementById('gameOver');
        link.click();
    }

    nextQuestion() {

        const right = Datos[Util.myRandom(Datos.length)];
        const possibles = this.choosePossibleAnswers(right.name);

        this.setState({
            right: right,
            possibles: possibles,
            answer: '',
            showAnswer: false,
        });

    }

    initGame() {
        const right = Datos[Util.myRandom(Datos.length)];
        const possibles = this.choosePossibleAnswers(right.name);

        this.setState({
            right: right,
            possibles: possibles,
            answer: '',
            showAnswer: false,
            score: 0,
            lifes: 3,
            newRecord : false,
        });

    }


    classButton(answer) {

        //console.log(answer);
        //console.log(this.isRight(answer));

        if (this.state.showAnswer) {
            if (this.isRight(answer)) {
                return "btn btn-lg btn-success btn-block";
            } else {
                return this.state.answer === answer ? "btn btn-lg btn-danger btn-block" : "btn btn-lg btn-warning btn-block"
            }
        }

        return "btn btn-lg btn-warning btn-block";

    }

    drawLifes() {
        let content = [];
        for (let i = 0; i < this.state.lifes; i++) {
            content.push(<img src="images/life24.svg" key={i} alt="life" width="24" height="24" title="life" />);
        }
        return content;
    };

    render() {
        const { name, flag } = this.state.right;
        return (
            <div id="Quiz" className="container p-2">
                <div className="contanier col-12 col-sm-10 col-md-6 col-lg-6 mx-auto pt-1 pb-5">
                    <div className="card">
                        <img src={flag} alt={name} className="card-img-top rounded flag-height" />
                        <div className="card-body">
                            {this.state.possibles.map(answer => (
                                <div className="container pt-2" key={answer}>
                                    <a key={answer} id={answer} className={this.classButton(answer)} href="/" onClick={this.handleOnclick}>{answer}</a>
                                </div>
                            ))}
                        </div>
                        <div className="card-footer text-center">
                            <div className="row">
                                <div className="col-3">
                                    {this.drawLifes()}
                                </div>
                                <div className="col-6">
                                    <h5>Score : {this.state.score}</h5>
                                </div>
                                <div className="col-3">
                                    <button id="pausa" className="btn btn-secondary btn-sm" data-toggle="modal" data-target="#modalPausa">Pause</button>
                                    <button id="gameOver" className="btn btn-secondary btn-sm" data-toggle="modal" data-target="#modalGameOver" hidden>Fin</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal id="modalPausa" title="Flags Quiz">
                    <button type="button" className="btn btn-success btn-block" data-dismiss="modal" onClick={this.handleOnClickInit}>Restart</button>
                    <button type="button" className="btn btn-danger btn-block" data-dismiss="modal" onClick={this.handleOnClickExit}>Exit Game</button>
                </Modal>
                <Modal id="modalGameOver" notClosed={true} title={this.state.newRecord?'Congratulations !!!' : 'Game Over'}>
                    {(this.state.newRecord ? 
                        <h2 className="text-success">New high score {this.state.score}</h2> : 
                        <h2>Score : {this.state.score}<small className="float-right">Your high score: {this.state.record}</small></h2>)}
                    <button type="button" className="btn btn-success btn-block" data-dismiss="modal" onClick={this.handleOnClickInit}>Play Again</button>
                    <button type="button" className="btn btn-danger btn-block" data-dismiss="modal" onClick={this.handleOnClickExit}>Exit Game</button>
                </Modal>
                <div id="divAudio">
                    <audio id="audioRight" controls hidden>
                        <source className="hidden" src="sounds/BellTransition.mp3" type="audio/mpeg" />
                    </audio>
                    <audio id="audioWrong" controls hidden>
                        <source className="hidden" src="sounds/HighHonk.m4a" type="audio/mp4" />
                    </audio>
                </div>
            </div>

        );
    }
}

export default Quiz;